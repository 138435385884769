* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

html, body {
  color:black;
  background-color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-align: center;
  direction: ltr;
  border-radius: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  padding: 15px 0px 15px 0px;
}

a {
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

ul {
  list-style: none;
}

#root {
  height: 100%;
}

/*header*/
#page-header {
  background-color: #202020;
  color: #a5a5a5;
  height: 54px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: fixed;
}
.header-title {
  font-size: 22px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  padding: 12px;
}
#navbar {
  display: flex;
  height: inherit;
  flex-grow: 1;
  flex-wrap: nowrap;
}

.navbar.nav-menu {
  height: inherit;
  display: flex;
  flex-wrap: nowrap;
}

.user {
  height: inherit;
  margin-left: auto;
}

.user-dropdown {
  height: inherit;
  width: 150px;
  margin-right: 12px;
  transition: background-color 300ms;
}

.user-dropdown-toggle{
  height: inherit;
  display: flex;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
}
.user-dropdown:hover {
  background-color: #3a3a3a;
}

.user-dropdown-content {
  color:black;
  background-color: #ffffff;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  border: 1px solid #efefef;
  text-align: left;
  display: none;
  width: inherit;
  z-index: 0;
  cursor: pointer;
}

.user-dropdown:hover .user-dropdown-content {
  display: block;
}

.nav-menu ul {
  position: static;
  display: flex;
  flex-wrap: nowrap;
}

.nav-menu li {
  position: relative;
  display: inline-block;
  height: 100%;
  cursor: pointer;
}

.nav-menu li > a{
  color: inherit;
  position: relative;
  display: flex;
  align-items: center;
  height: 54px;
  padding: 0px 20px 0px 20px;
  transition: background-color 300ms;
}
.nav-menu li > a:hover{
  background-color: #3a3a3a;
}
.nav-menu li > a.active {
  background-color: #005e5e;
}

.user-dropdown-content li {
  color: inherit;
  display: flex;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  transition: background-color 300ms;
} 

.user-dropdown-content li:hover {
  background-color: #d8d8d8;
} 

/* Login */
#login {
  width: 100%;
  height: 100%;
  background-color: #444;
  align-content: center;
  overflow-y: auto;
}

.login-form {
  width: 360px;
  padding: 30px;
  margin: auto;
  margin-top: 5rem;
  vertical-align: middle;
  background-color: white;
}

.btn-login {
  width: 100%;
  margin: 5px 0px 5px 0px;
  padding: 6px;
  text-align: center;
  justify-content: center;
}

.action-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 5px;
}

.action-group > .btn {
  width: 40%;
  margin: 0px;
  padding: 6px;
  text-align: center;
  justify-content: center;
}

/* page body */
.page-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  margin: 10px 6% 10px 6%;
  margin-top:64px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 5px 0px;
}

.page-body {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.page-body ul {
  justify-content: left;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 5px;
  overflow-y: scroll;
  border-style: solid;
  border-width: 1px;
  border-color: #202020;
}

.page-body li {
  padding: 1.5px 0px 1.5px 0px;
  background-color: #f3f3f3;
}

.page-body li:nth-child(even) {
  background-color: #dddddd;
}

/*Modal menus*/

.Modal {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid #dddddd;
  background: white none repeat scroll 0% 0%;
  overflow: auto;
  border-radius: 4px;
  outline: currentcolor none medium;
  padding: 30px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 30%;
}

@media (max-height: 400px) {
  .Modal{
    overflow-y: scroll !important;
    max-height: 95%;
    scrollbar-width: none;
   }
  .Modal::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 1120px) and (min-width: 768px) {
  .Modal {
    width: 60%; 
  }
}

@media only screen and (max-width: 768px) {
  .Modal {
    width: 90%;  
  }
}

/*Account creation form*/
.acc-form {
  text-align: left;
}

.acc-form label {
  padding: 2px 0px 2px 0px;
}

.acc-form .btn {
  float: right;
  margin: 2px 0px 0px 0px;
}
.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-error{
  display: block;
  color: red;
  text-align: right;
  font-size: 12px;
}

.form-error:empty:before {
  content: "\200b";
}

.login-error{
  display: block;
  color: red;
  text-align: center;
  font-size: 15px;
  margin-bottom: 2px;
}

.login-error:empty:before {
  content: "\200b";
}

.search {
  display: flex;
  justify-content: center;
  width: 90%;
}

.search-input {
  width: 40%;
}

.title {
  font-size: 25px;
}

/*icons*/

.fa-btn {
  margin-right: 5px;
}

.fa-user {
  font-size: 25px;
  margin-right: 10px;
}

.fa-angle-down {
  margin-left: 5px;
}

.fa-inline-menu {
  margin-left: 5px;
  margin-right: 5px;
}

/* Buttons/Inputs */

input[type="checkbox"] {
  margin: 0px 8px 0px 8px;
  filter: hue-rotate(320deg);
  cursor: pointer;
}

input[type="text"], input[type="password"], input[type="email"] {
  cursor: text;
  padding: .05rem .75rem;
  outline: none;
  border: 1px solid #ced4da;
  transition: border-color 150ms;
}

input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus {
  border-color: #3a3a3a;
}

input[type="number"] {
  cursor: text;
  padding: 0px;
  outline: none;
  border: 1px solid #ced4da;
  transition: border-color 150ms;
  text-align: right;
}

input[type="number"]:focus {
  border-color: #3a3a3a;
}

.btn {
  display: flex;
  align-items: center;
  border-width: 0;
  font-size: 15px;
  transition: background-color 300ms;
  cursor: pointer;
}

.btn-group {
  display:flex;
  align-items: center;
  justify-content: center;
}

.btn-action {
  flex-shrink: 0;
  padding: 6px;
  margin: 0px 5px 0px 5px;
  min-width: max-content;
}

.btn-red {
  background-color: #d84a38;
  color: white;
}
.btn-red:hover {
  background-color: #f1533f;
}

.btn-nav {
  border-width: 0;
transition: background-color 300ms;
padding: 2px 5px 2px 5px;
}